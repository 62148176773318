body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
.wd-100{
  width: 100%;
}

.btn{
  font-family: "Raleway", sans-serif;
    background-color: #ad9175 !important;
    color: #fff;
    padding: 10px 20px;
    box-shadow: rgba(132, 104, 76, 0.2) 0px 4px 10px;
    transition: all 0.4s ease-in-out;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.btn:hover {
  background-color: #3f4e60 !important;
  color: #fff !important;
  box-shadow: rgba(132, 104, 76, 0.4) 0px 6px 12px;
}

.banner_form {
  border: 3px solid #ad9175;
  padding: 30px;
  background-color: #fff;
  top: 15%;
  right: 10%;
  position: absolute;
}

form {
  display: block;
  margin-top: 0em;
  unicode-bidi: isolate;
}

.master_div {
  border: 1px solid #3f4e60;
}

.master_div h6 {
  line-height: 50px;
  border-bottom: 1px solid #3f4e60;
}

.gallery_sec {
  background-color: #f7f7f7;
  padding: 80px 0;
}

.ameniti_sec ::marker {
  color: #ad9175;
}

footer {
  background-color: #caa98a !important;
  color: #fff;
}

.map_wrap {
  border: 1px solid grey;
  border-radius: 20px;
  width: 80%;
  height: 402px;
  margin: auto;
}
.txt-center{
  text-align: center;
}
.proj_div{
  border: 1px solid #CAA88A;
  padding: 10px;
  display: grid;
  border-radius: 4px;
  justify-items: center;
}
.proj_sec .container {
  background:#f7f7f7
}
.navbar .container a >  img{
  width: 65%;
}
.ameniti_div {
  display: flex;
  align-items: center;
  gap: 15px;
}
.nav-item a{
  border-bottom: 1px solid transparent;
}
.nav-item a:hover{
  border-bottom: 1px solid #CAA88A;
}
.footer-links-desc{
display: grid;
grid-template-columns: 40% 1fr;
}
.newsletter{
  width: 100%;
  display: grid;
  justify-items: center;

}
.newsletter .subscribe-input{
  width: 50%;
  border-radius: 10px 0 0 10px;
}
.susbcribe-button{
  height: 60px;
  border-radius: 0 10px 10px 0 !important;
}
h3{
  font-family: "Baskervville SC", serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
h3::before{
  content: "";
    background-color: #3f4e60;
    width: 40px;
    height: 2px;
    top: 0;
    bottom: 0;
    flex-shrink: 0;
    position: relative;
    margin-left: 10px;
}
h3::after{
  content: "";
    background-color: #3f4e60;
    width: 40px;
    height: 2px;
    top: 0;
    bottom: 0;
    flex-shrink: 0;
    position: relative;
    margin-left: 10px;
}


@media screen and (max-width: 500px) {
  h3{
    justify-content: center !important;
  }
  .navbar .container a >  img{
    width: 100px;
  }
  .banner_form{
  position: relative !important;
  margin-top: -10px;
  margin-left: 50px;
  }
  .banner_sec {
    display: grid;
  }
  .ameniti_div{
    display: grid !important;
    gap: 10px;
    justify-items: center;
  }
  .newsletter .subscribe-input{
    width: 80%;
    border-radius: 10px 0 0 10px;
  }
  .footer-links-desc{
    display: grid;
    grid-template-columns: none; 
    grid-template-rows: 1fr 1f !important;
  }  
}

.modalButton{
  display:flex !important;
  align-items: center;
  justify-content: space-between;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
}